import { hashCode } from '../shared-logic/text';

export const vibrantColors = [
  '#1abc9c', // turquoise
  '#2ecc71', // emerald
  '#3498db', // peter river
  '#9b59b6', // amethyst
  '#34495e', // wet asphalt
  '#16a085', // green sea
  '#27ae60', // nephritis
  '#2980b9', // belize hole
  '#8e44ad', // wisteria
  '#2c3e50', // midnight blue
  '#f1c40f', // sun flower
  '#e67e22', // carrot
  '#e74c3c', // alizarin
  '#0984e3', // electron blue
  '#95a5a6', // concrete
  '#f39c12', // orange
  '#d35400', // pumpkin
  '#c0392b', // pomegranate
  '#bdc3c7', // silver
  '#7f8c8d', // asbestos
];

export function randomColor(seed?: string): string {
  const index = seed
    ? Math.abs(hashCode(seed))
    : Math.floor(Math.random() * 10000);
  return vibrantColors[index % vibrantColors.length];
}
