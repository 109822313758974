import React from 'react';
import { styled } from 'styled-components';

type Props = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  color?: 'primary' | 'secondary' | 'accent';
};

const Button = React.forwardRef<HTMLButtonElement, Props>(
  function B(props, ref) {
    const { color = 'primary', ...buttonProps } = props;
    switch (color) {
      case 'accent':
        return <AccentButton ref={ref} {...buttonProps} />;
      case 'secondary':
        return <SecondaryButton ref={ref} {...buttonProps} />;
      default:
        return <PrimaryButton ref={ref} {...buttonProps} />;
    }
  },
);

export const BaseButton = styled.button`
  border: 0;
  line-height: 2rem;
  border-radius: 1rem;
  padding: 0 var(--space-4);
  cursor: pointer;
  transition: all 99ms ease-out;
  &:hover:not(:disabled) {
    transform: scale(1.1, 1.1);
  }
  & svg {
    width: 1.2rem;
    height: 1.2rem;
    vertical-align: text-bottom;
  }
  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
`;

export const PrimaryButton = styled(BaseButton)`
  color: var(--on-primary);
  background-color: var(--primary);
  &:focus {
    outline: none;
    background-color: var(--primary);
  }
  & svg {
    fill: var(--on-primary);
  }
`;

export const AccentButton = styled(BaseButton)`
  color: var(--on-primary);
  background-color: var(--secondary);
  &:focus {
    outline: none;
    background-color: var(--primary);
  }
  & svg {
    fill: var(--on-primary);
  }
`;

export const SecondaryButton = styled(BaseButton)`
  color: var(--on-primary);
  background-color: var(--inactive);
  &:focus {
    outline: none;
    background-color: var(--inactive);
  }
  & svg {
    fill: var(--on-primary);
  }
`;

export default Button;
