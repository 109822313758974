function fallbackCopyTextToClipboard(
  text: string,
  resolve: () => void,
  reject: (err: Error) => void,
) {
  const textArea = document.createElement('textarea');
  textArea.value = text;

  // Avoid scrolling to bottom
  textArea.style.top = '0';
  textArea.style.left = '0';
  textArea.style.position = 'fixed';

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    const successful = document.execCommand('copy');
    if (successful) {
      resolve();
    } else {
      reject(new Error('Fallback: Oops, unable to copy'));
    }
  } catch (err) {
    reject(err as Error);
  }

  document.body.removeChild(textArea);
}

export function copyTextToClipboard(text: string): Promise<void> {
  if (!navigator.clipboard) {
    return new Promise((resolve, reject) => {
      fallbackCopyTextToClipboard(text, resolve, reject);
    });
  }
  return navigator.clipboard.writeText(text);
}
