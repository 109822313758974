import * as React from 'react';
import * as Sentry from '@sentry/react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { AppContextProvider } from '../../context/app.context';
import SignIn from '../sign-in/SignIn';
import Authenticated from './Authenticated';
import GlobalErrorBoundary from '../error-fallback/GlobalErrorBoundary';
import { getBaseRoute } from '../../integration';
import { initSentry } from '../../integration/sentry';

initSentry();

function App() {
  return (
    <AppContextProvider>
      <Router basename={getBaseRoute()}>
        <GlobalErrorBoundary>
          <Routes>
            <Route path="/signin" element={<SignIn />} />
            <Route path="*" element={<Authenticated />} />
          </Routes>
        </GlobalErrorBoundary>
      </Router>
    </AppContextProvider>
  );
}

export default Sentry.withProfiler(App);
