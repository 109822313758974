import { styled } from 'styled-components';
import { BoxSkeleton } from './index';

const SelectSkeleton = styled(BoxSkeleton)`
  padding: 0;
  height: 53px;
  min-width: 10rem;
  margin: var(--space-2) var(--space-3);
`;

export default SelectSkeleton;
