import React, { ReactElement } from 'react';
import { styled } from 'styled-components';
import { BoxSkeleton } from './index';
import Card from '../card';

type Props = {
  numberOfSections?: number;
  numberOfSectionItems?: number;
};

function SectionListSkeleton({
  numberOfSections = 8,
  numberOfSectionItems = 1,
}: Props): ReactElement {
  return (
    <List>
      {Array.from(Array(numberOfSections).keys()).map(key => (
        <li key={key}>
          <SectionPlaceHolder />
          <ul>
            {Array.from(Array(numberOfSectionItems).keys()).map(itemKey => (
              <Paper key={`${key}-${itemKey}`} as="li">
                <TitlePlaceholder />
                <SubtitlePlaceholder />
              </Paper>
            ))}
          </ul>
        </li>
      ))}
    </List>
  );
}

const List = styled.ul`
  display: flex;
  flex-direction: column;
`;

const SectionPlaceHolder = styled(BoxSkeleton)`
  width: 16rem;
  padding: 0;
  height: 1rem;
  margin: var(--space-2);
`;

const Paper = styled(Card)`
  padding: var(--space-2);
`;

const TitlePlaceholder = styled(BoxSkeleton)`
  width: 50%;
`;

const SubtitlePlaceholder = styled(BoxSkeleton)`
  width: 30%;
  height: 1rem;
  padding: 0;
  margin-top: var(--space-2);
`;

export default SectionListSkeleton;
