import { useReducer, useEffect, Reducer } from 'react';
import { apiGet } from '.';
import {
  ListResponse,
  DetailResponse,
  ListWithValuesResponse,
  ApiListResponse,
} from '../models';

export type RequestState<T> =
  | { status: 'idle'; data: undefined; error: undefined }
  | { status: 'loading'; data: undefined; error: undefined }
  | { status: 'success'; data: T; error: undefined }
  | { status: 'error'; error: Error; data: undefined };

type RequestAction<T> =
  | { type: 'start-request' }
  | { type: 'response-success'; data: T }
  | { type: 'response-error'; error: Error };

export function useApiGet<T>(
  route: string,
  options: { skip: boolean } = { skip: false },
): RequestState<T> {
  const initialState: RequestState<T> = {
    status: 'idle',
    data: undefined,
    error: undefined,
  };
  const [state, dispatch] = useReducer<
    Reducer<RequestState<T>, RequestAction<T>>
  >(reducer, initialState);
  useEffect(() => {
    let subscribed = true;
    if (route && !options.skip) {
      dispatch({ type: 'start-request' });
      apiGet<T>(route)
        .then(data => {
          if (subscribed) {
            dispatch({ type: 'response-success', data });
          }
        })
        .catch(error => {
          if (subscribed) {
            dispatch({ type: 'response-error', error });
          }
        });
    }
    return () => {
      subscribed = false;
    };
  }, [route, options.skip]);
  return state;
}

export function reducer<T>(
  state: RequestState<T>,
  action: RequestAction<T>,
): RequestState<T> {
  switch (action.type) {
    case 'start-request':
      return {
        status: 'loading',
        data: undefined,
        error: undefined,
      };
    case 'response-success':
      return {
        status: 'success',
        data: action.data,
        error: undefined,
      };
    case 'response-error':
      return {
        status: 'error',
        error: action.error,
        data: undefined,
      };
    default:
      return state;
  }
}

export type ListRequestState<T> = RequestState<ListResponse<T>>;
export type ApiListRequestState<T> = RequestState<ApiListResponse<T>>;
export type ListWithValuesRequestState<T> = RequestState<
  ListWithValuesResponse<T>
>;
export type DetailRequestState<T> = RequestState<DetailResponse<T>>;

export function getValueOrDefault<T>(
  response: RequestState<T>,
  defaultValue: T,
): T {
  if (response.status === 'success') {
    return response.data;
  }
  return defaultValue;
}

export function getValueOrUndefined<T>(
  response: RequestState<T>,
): T | undefined {
  if (response.status === 'success') {
    return response.data;
  }
  return undefined;
}

export function getErrorOrUndefined<T>(
  response: RequestState<T>,
): Error | undefined {
  if (response.status === 'error') {
    return response.error;
  }
  return undefined;
}
