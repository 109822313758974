export const getFocusableElements = (
  parent?: HTMLElement | null,
): HTMLElement[] => {
  if (!parent) return [];

  return (
    Array.from(
      parent.querySelectorAll(
        'a[href], button, input:not([type="hidden"]), textarea, select, details,[tabindex]',
      ),
    )
      .filter(isTabbable)
      // sort tabindexes as follows: 1, 2, 3, 4, ..., 0, 0, 0
      .sort((a, b) => {
        const aIndex = Number(a.getAttribute('tabindex')) ?? 0;
        const bIndex = Number(b.getAttribute('tabindex')) ?? 0;
        if (aIndex === bIndex) return 0;
        if (aIndex === 0) return 1;
        if (bIndex === 0) return -1;
        return aIndex < bIndex ? -1 : 1;
      }) as HTMLElement[]
  );
};

function isTabbable(el: Element) {
  return (
    el.getAttribute('tabindex') !== '-1' &&
    !el.hasAttribute('disabled') &&
    !el.getAttribute('aria-hidden') &&
    isVisible(el)
  );
}

function isVisible(el: Element) {
  return el.getClientRects().length > 0;
}

export const nextFocus = (elements: HTMLElement[], forward = true) => {
  const currentIndex = elements.findIndex(e => e === document.activeElement);
  let nextIndex = 0;

  if (currentIndex > -1) {
    if (forward) {
      nextIndex = currentIndex < elements.length - 1 ? currentIndex + 1 : 0;
    } else {
      nextIndex = currentIndex > 0 ? currentIndex - 1 : elements.length - 1;
    }
  }

  elements[nextIndex]?.focus();
};

export function focusNextElement(
  container: HTMLElement | null,
  forward = true,
) {
  return nextFocus(getFocusableElements(container), forward);
}

export function setVisibilityForScreenReaders(
  portalNode: HTMLElement,
  open: boolean,
) {
  // All'apertura del dialog imposta gli altri elementi nella pagina come nascosti
  for (const child of Array.from(document.body.children)) {
    const isDialogNode = child === portalNode;
    if (isDialogNode) {
      setAriaHidden(child, !open);
    } else {
      setAriaHidden(child, open);
    }
  }
}

function setAriaHidden(element: Element, hidden: boolean) {
  if (hidden) {
    element.setAttribute('aria-hidden', 'true');
  } else {
    element.removeAttribute('aria-hidden');
  }
}
