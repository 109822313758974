import * as React from 'react';
import { styled } from 'styled-components';
import BoxSkeleton from './BoxSkeleton';

function DialogFormSkeleton() {
  return (
    <>
      <RadioSkeleton />
      <TextAreaSkeleton />
      <Actions>
        <SubmitBtn />
      </Actions>
    </>
  );
}

const RadioSkeleton = styled(BoxSkeleton)`
  height: 142px;
  margin-bottom: var(--space-3);
`;

const TextAreaSkeleton = styled(BoxSkeleton)`
  height: 84px;
  margin-bottom: var(--space-4);
`;

const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const SubmitBtn = styled(BoxSkeleton)`
  width: 100px;
`;

export default DialogFormSkeleton;
