import * as React from 'react';
import { styled } from 'styled-components';
import Alert from './Alert';
import Warning from '../../../assets/icons/exclamation-triangle.svg';

type Props = React.PropsWithChildren<{
  className?: string;
}>;

const WarningAlert = ({ children, className }: Props) => {
  return (
    <AlertBox className={className} Icon={Icon}>
      {children}
    </AlertBox>
  );
};

const AlertBox = styled(Alert)`
  background-color: var(--warning-container);
  color: var(--warning);
`;

const Icon = styled(Warning)`
  fill: var(--warning);
`;

export default WarningAlert;
