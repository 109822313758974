import React, { ReactElement } from 'react';
import { styled } from 'styled-components';
import Card from '../card';
import BoxSkeleton from './BoxSkeleton';

function ListDetailSkeleton(): ReactElement {
  const numberOfRows = 5;
  return (
    <Paper>
      <ul>
        {Array.from(Array(numberOfRows).keys()).map(key => (
          <li key={key}>
            <ListItem />
          </li>
        ))}
      </ul>
    </Paper>
  );
}

const Paper = styled(Card)`
  padding: var(--space-3);
`;

const ListItem = styled(BoxSkeleton)`
  margin-top: var(--space-2);
  height: 3rem;
`;

export default ListDetailSkeleton;
