import React, { useState } from 'react';
import { styled, css } from 'styled-components';

import ArrowUp from '../../../assets/icons/chevron-up-solid.svg';
import ArrowDown from '../../../assets/icons/chevron-down-solid.svg';
import MenuItem from '../menu-item';
import Avatar from '../avatar';
import { useAppContext } from '../../context/app.context';
import {
  studentDisplayName,
  studentSchoolYear,
} from '../../shared-logic/student';

function StudentInfo() {
  const [{ student, students }] = useAppContext();
  const [open, setOpen] = useState(false);
  if (!student) return null;

  function toggle() {
    setOpen(isOpen => !isOpen);
  }

  const name = studentDisplayName(student);
  const hasStudents = students && students.length > 1;
  return (
    <Box>
      <MenuButton onClick={toggle} aria-expanded={open}>
        <Avatar name={name} size="1.5rem" ariaHidden />
        <Info>
          <Button
            aria-label={`${name} ${student.classe} ${studentSchoolYear(
              student,
            )}`}
            aria-expanded={open}
            onClick={e => {
              e.stopPropagation();
              toggle();
            }}
          >
            <Name>{name}</Name>
          </Button>
          <small aria-hidden="true">
            {studentSchoolYear(student)} • {student.classe} •{' '}
            {student.statoFrequenza}
          </small>
        </Info>
        {open ? <Collapse /> : <Expand />}
      </MenuButton>
      <Panel aria-expanded={open} $hasStudents={hasStudents}>
        {open && (
          <>
            <MenuItem name="Selezione anno/classe" path="/anni-scolastici" />
            {hasStudents && (
              <MenuItem
                name="Selezione studente"
                path="/alunni?redirect=false"
              />
            )}
          </>
        )}
      </Panel>
    </Box>
  );
}

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

const Box = styled.div`
  padding: 0 var(--space-2);
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
`;

const Info = styled(Column)`
  flex-grow: 1;
  align-items: flex-start;
  overflow: hidden;
  padding-top: var(--space-2);
  padding-bottom: var(--space-2);
  margin-left: var(--space-2);
`;

const Expand = styled(ArrowDown)`
  width: 1.1rem;
  height: 1.1rem;
  fill: var(--inactive);
  margin-left: var(--space-1);
`;

const Collapse = styled(ArrowUp)`
  width: 1.1rem;
  height: 1.1rem;
  fill: var(--on-primary);
  margin-left: var(--space-1);
`;

const MenuButton = styled.div`
  cursor: pointer;
  display: flex;
  position: relative;
  align-items: center;
  border-radius: 0.5rem;
  border: 1px solid transparent;
  transition: all 0.3s ease-out;
  ${({ 'aria-expanded': ariaExpanded }) => css`
    margin-bottom: var(--space-2);
    padding: 0 var(--space-2);
    &:focus-within {
      border: 1px solid var(--secondary);
    }
    ${ariaExpanded
      ? css`
          background-color: var(--secondary);
          color: var(--on-primary);
          &:hover {
            opacity: 0.8;
          }
        `
      : css`
          &:hover {
            background-color: var(--primary-container);
          }
        `}
  `}
`;

const Button = styled.button`
  cursor: pointer;
  color: inherit;
  padding: 0;
  background: none;
  outline: none;
  border: none;
  &::after {
    position: absolute;
    content: '';
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
  }
`;

const Name = styled.span`
  white-space: nowrap;
`;

const Panel = styled.div<{ $hasStudents?: boolean }>`
  transition: all 0.2s ease-in;
  height: ${props => (props.$hasStudents ? '104px' : '52px')};
  overflow: hidden;
  ${props =>
    !props['aria-expanded'] &&
    css`
      height: 0;
    `}
`;

export default StudentInfo;
