import React from 'react';
import { ConteggioNotifiche } from '../models';

type NotificationsContextState = {
  conteggio?: ConteggioNotifiche;
  stale: boolean;
};

type NotificationsContextType = [
  NotificationsContextState,
  React.Dispatch<React.SetStateAction<NotificationsContextState>>,
];

const NotificationsContext =
  React.createContext<NotificationsContextType | null>(null);

function NotificationsContextProvider({
  children,
}: React.PropsWithChildren<object>) {
  const state = React.useState<NotificationsContextState>({ stale: true });
  return (
    <NotificationsContext.Provider value={state}>
      {children}
    </NotificationsContext.Provider>
  );
}

function useNotificationsContext() {
  const context = React.useContext(NotificationsContext);
  if (!context)
    throw new Error('NotificationsContext must be used inside its Provider');

  return context;
}

function useConteggioNotifiche() {
  const [{ conteggio }] = useNotificationsContext();
  return conteggio;
}

function useIsConteggioNotificheStale() {
  const [{ stale }] = useNotificationsContext();
  return stale;
}

function useSetConteggioNotifiche() {
  const [, setConteggio] = useNotificationsContext();
  return React.useCallback(
    (conteggio: ConteggioNotifiche) =>
      setConteggio({ conteggio, stale: false }),
    [setConteggio],
  );
}

function useInvalidateConteggioNotifiche() {
  const [, setConteggio] = useNotificationsContext();
  return React.useCallback(
    () => setConteggio(prevState => ({ ...prevState, stale: true })),
    [setConteggio],
  );
}

export {
  NotificationsContextProvider,
  useConteggioNotifiche,
  useIsConteggioNotificheStale,
  useSetConteggioNotifiche,
  useInvalidateConteggioNotifiche,
};
