import React, { useState, useEffect, Dispatch, SetStateAction } from 'react';
import { styled, css } from 'styled-components';
import { useSnackbarContext } from '../../context/snackbar.context';

type Props = {
  children: React.ReactNode;
  open: boolean;
  color?: string;
  backgroundColor?: string;
};

const Snackbar: React.FC<Props> = ({
  children,
  backgroundColor,
  color,
  open,
}) => {
  return (
    <Box
      role="alert"
      $open={open}
      $backgroundColor={backgroundColor}
      $color={color}
    >
      {children}
    </Box>
  );
};

export function useSnackbarState(
  durationInMilliseconds = 5000,
): [boolean, Dispatch<SetStateAction<boolean>>] {
  const [open, setOpen] = useState(false);
  useEffect(() => {
    if (open) {
      const timeout = setTimeout(() => {
        setOpen(false);
      }, durationInMilliseconds);
      return () => {
        clearTimeout(timeout);
      };
    }
  }, [durationInMilliseconds, open]);

  return [open, setOpen];
}

export function useSnackbarMessage(
  durationInMilliseconds = 5000,
): [string, Dispatch<SetStateAction<string>>] {
  const [message, setMessage] = useState('');
  useEffect(() => {
    if (message) {
      const timeout = setTimeout(() => {
        setMessage('');
      }, durationInMilliseconds);
      return () => {
        clearTimeout(timeout);
      };
    }
  }, [durationInMilliseconds, message]);

  return [message, setMessage];
}

export function useAutoShowSnackbar(condition: boolean, message: string) {
  const [, setSnackMessage] = useSnackbarContext();

  useEffect(() => {
    if (condition) {
      setSnackMessage(message);
    }
  }, [condition, message, setSnackMessage]);
}
type BoxProps = {
  $open: boolean;
  $color?: string;
  $backgroundColor?: string;
};
const Box = styled.div<BoxProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 6px;
  position: absolute;
  bottom: 0;
  left: 0;
  opacity: 0;
  z-index: var(--z-index-snackbar);
  transition:
    bottom 0.5s ease,
    opacity 0.5s ease;
  ${({ $backgroundColor, $color, $open }) => css`
    background-color: ${$backgroundColor || 'var(--on-background)'};
    color: ${$color || 'var(--surface)'};
    margin: var(--space-3);
    padding: var(--space-3);
    ${$open &&
    css`
      bottom: 0;
      opacity: 1;
    `};
  `};
`;

export default Snackbar;
