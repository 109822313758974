import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useLayoutEffect,
  ReactElement,
  ReactNode,
  Dispatch,
  SetStateAction,
} from 'react';
import matchMedia from '../hooks/matchMedia';
import { tablet } from '../styles/sizes';

type SidebarContextType = [boolean, Dispatch<SetStateAction<boolean>>];

const initialContext: SidebarContextType = [false, () => ({})];
const SidebarContext = createContext(initialContext);

export function useSidebarContext(): SidebarContextType {
  return useContext(SidebarContext);
}

type Props = {
  children: ReactNode;
};

export function SidebarContextProvider({ children }: Props): ReactElement {
  const state = useState(false);
  return (
    <SidebarContext.Provider value={state}>{children}</SidebarContext.Provider>
  );
}

export function useCloseSidebarAfterNavigationOnMobile(pathname: string): void {
  const [, setSidebarOpen] = useSidebarContext();
  useEffect(() => {
    const { matches } = matchMedia(`screen and (max-width: ${tablet})`);
    if (matches) {
      setSidebarOpen(false);
    }
  }, [pathname, setSidebarOpen]);
}

export function useSetSidebarInitiallyOpenOnDesktop(): void {
  const [, setSidebarOpen] = useSidebarContext();
  useLayoutEffect(() => {
    function setSidebarInitiallyOpenOnDesktop() {
      const { matches } = matchMedia(`screen and (min-width: ${tablet})`);
      if (matches) {
        setSidebarOpen(true);
      }
    }

    setSidebarInitiallyOpenOnDesktop();
  }, [setSidebarOpen]);
}
