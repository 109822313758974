import * as React from 'react';
import { signIn } from '../../api/auth';
import storage, { Keys } from '../../shared-logic/storage';
import { useAppContext } from '../../context/app.context';
import { Navigate } from 'react-router-dom';
import { styled } from 'styled-components';
import ErrorAlert from '../../components/alerts/ErrorAlert';
import Card from '../../components/card/Card';
import Button from '../../components/buttons/Button';
import TextInput from '../../components/forms/TextInput';
import { FetchError } from '../../api/fetch-error';

function SignIn() {
  const [appContext, dispatch] = useAppContext();
  const [username, setUsername] = React.useState(initialCredentials.username);
  const [password, setPassword] = React.useState(initialCredentials.password);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState<FetchError | null>(null);
  const authenticate = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    e.preventDefault();
    setLoading(true);
    try {
      const user = await signIn(username, password);
      storage.setObject(Keys.user, user);
      dispatch({ type: 'set-user', user });
    } catch (e) {
      setError(e as FetchError);
      setLoading(false);
    }
  };

  if (appContext.user) {
    return <Navigate to="/" />;
  }

  return (
    <Container>
      <Card>
        <Title>Nuvola</Title>
        {error && (
          <ErrorAlert>{error.data?.message || error.message}</ErrorAlert>
        )}
        <Form>
          <TextInput
            label="Username"
            value={username}
            onChange={setUsername}
            autoComplete="username"
            placeholder="Inserisci lo username"
            required
          />
          <TextInput
            label="Password"
            value={password}
            onChange={setPassword}
            autoComplete="current-password"
            placeholder="Inserisci la password"
            secured
            required
          />
          <SignInButton type="submit" onClick={authenticate}>
            {loading ? 'Autenticazione...' : 'Accedi'}
          </SignInButton>
        </Form>
      </Card>
    </Container>
  );
}

const initialCredentials =
  process.env.NODE_ENV === 'development'
    ? { username: 'johnhiggins', password: 'j0hnHiggin$' }
    : { username: '', password: '' };

const Container = styled.div`
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--background);
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 30vw;
  margin: var(--space-3);
`;

const SignInButton = styled(Button)`
  margin-top: var(--space-3);
`;

const Title = styled.h1`
  text-align: center;
  color: var(--primary);
  margin-top: var(--space-3);
`;

export default SignIn;
