export const Keys = {
  user: '__user__',
  student: '__student__',
  students: '__students__',
  settings: '__institutesettings__',
  expiry: '__expiry__',
};

function getString(key: string): string | null {
  return localStorage.getItem(key);
}

function setString(key: string, value: string): void {
  localStorage.setItem(key, value);
}

function getObject<T>(key: string): T | null {
  const json = localStorage.getItem(key);
  if (json) {
    return JSON.parse(json) as T;
  }
  return null;
}

function setObject<T>(key: string, value: T): void {
  localStorage.setItem(key, JSON.stringify(value));
}

function setExpiration(): void {
  const expirationInDays = 1;
  const expiration = new Date();
  expiration.setDate(expiration.getDate() + expirationInDays);
  setDate(Keys.expiry, expiration);
}

function getExpiration(): Date | null {
  return getDate(Keys.expiry);
}

function getDate(key: string): Date | null {
  const dateString = localStorage.getItem(key);
  return dateString ? new Date(dateString) : null;
}

function setDate(key: string, date: Date): void {
  localStorage.setItem(key, date.toISOString());
}

function clear(): void {
  localStorage.clear();
}

function remove(key: string): void {
  localStorage.removeItem(key);
}

export default {
  getString,
  setString,
  getObject,
  setObject,
  getExpiration,
  setExpiration,
  clear,
  remove,
};
