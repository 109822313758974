import { styled, css } from 'styled-components';
import TextButton from './TextButton';

const IconButton = styled.button<{ $size?: string }>`
  cursor: pointer;
  background: none;
  border: none;
  display: flex;
  align-items: center;
  line-height: 0;
  border-radius: 0.5rem;
  transition:
    background-color 0.2s,
    color 0.2s;
  ${({ $size }) => css`
    padding: var(--space-2);
    color: var(--inactive);
    & > svg {
      width: ${$size || '1.5rem'};
      height: ${$size || '1.5rem'};
      fill: var(--inactive);
    }
    &:hover {
      background-color: var(--primary-container);
    }
    &:focus {
      outline: none;
      background-color: var(--primary-container);
    }
    &:active:not(:disabled) {
      background-color: var(--secondary);
      color: var(--on-primary);
      & > svg {
        fill: var(--on-primary);
      }
    }
    &:disabled,
    &[aria-disabled='true'] {
      cursor: not-allowed;
      background-color: transparent;
      opacity: 0.7;
    }
  `}
`;

export const DangerIconButton = styled(IconButton)`
  & > svg {
    fill: var(--error);
  }
  &:hover {
    background-color: var(--error-container);
  }
  &:focus {
    background-color: var(--error-container);
  }
  &:active:not(:disabled) {
    background-color: var(--error);
    & > svg {
      fill: var(--surface);
    }
  }
`;

export const TextIconButton = styled(TextButton)`
  display: flex;
  flex-direction: column;
  width: 4.5rem;
  font-size: var(--font-size-small);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: var(--space-1);
  padding: var(--space-1) 0rem;
  & > svg {
    margin: 0;
  }
`;

export default IconButton;
