import * as Sentry from '@sentry/react';
import { useAppContext } from '../context/app.context';
import storage from '../shared-logic/storage';

function appAttribute(key: string): string | undefined {
  const value = document
    .getElementById('app')
    ?.getAttribute(key)
    ?.replace(/\/$/, '');
  return value || undefined;
}

export function getBaseRoute(): string | undefined {
  return appAttribute('data-base-path');
}

export function getBaseUrl(): string | undefined {
  return appAttribute('data-base-url');
}

export function getApiUrl(): string | undefined {
  return appAttribute('data-api-url');
}

export function useLogout(): () => void {
  const [{ user }, dispatch] = useAppContext();
  const baseUrl = getBaseUrl();
  return () => {
    storage.clear();
    Sentry.getCurrentScope()
      .setUser(null)
      .setContext('session', null)
      .setTag('codiceCliente', '');
    if (user?.isImpersona && baseUrl) {
      // L'utente è un super admin autenticato da Nuvola, il logout consiste
      // in un redirect alla pagina di uscita dall'impersona
      location.href = `${baseUrl}?impersona=_exit`;
    } else if (baseUrl) {
      // La presenza del tag <base> nella pagina HTML indica che l'app
      // è in esecuzione all'interno di Nuvola, quindi il logout va fatto
      // dalla route di Nuvola
      location.href = `${baseUrl}/logout`;
    } else {
      dispatch({ type: 'clear-context' });
    }
  };
}
