import storage, { Keys } from './storage';
import { Student } from '../models';

export function isTutore(): boolean {
  const selectedStudent = getSelectedStudent();
  return selectedStudent ? selectedStudent.isTutore : false;
}

export function isStudente(): boolean {
  const selectedStudent = getSelectedStudent();
  return selectedStudent ? !selectedStudent.isTutore : false;
}

function getSelectedStudent(): Student | null {
  return storage.getObject<Student>(Keys.student);
}
