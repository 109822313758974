import { useState, useEffect, useCallback } from 'react';

export type AsyncState<T> =
  | { status: 'idle' }
  | { status: 'loading' }
  | { status: 'success'; value: T }
  | { status: 'error'; error: Error };

export function useAsync<T>(
  asyncFunction: () => Promise<T>,
  immediate = true,
): [AsyncState<T>, () => Promise<void>] {
  const [state, setState] = useState<AsyncState<T>>({ status: 'idle' });

  const execute = useCallback(async () => {
    if (state.status === 'idle') {
      setState({ status: 'loading' });
      try {
        const value = await asyncFunction();
        setState({ status: 'success', value });
      } catch (error) {
        setState({ status: 'error', error: error as Error });
      }
    }
  }, [asyncFunction, state.status]);

  useEffect(() => {
    if (immediate) {
      execute();
    }
  }, [execute, immediate]);

  return [state, execute];
}
