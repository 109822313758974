import React, { ReactElement } from 'react';
import { styled, keyframes } from 'styled-components';

type Props = {
  color?: string;
  size?: string;
  withMargin?: boolean;
};

function Spinner({
  color,
  size = '32px',
  withMargin = false,
}: Props): ReactElement {
  return (
    <Flex $withMargin={withMargin}>
      <Container $size={size} data-testid="spinner">
        <DoubleBounce color={color} />
        <DoubleBounce2 color={color} />
      </Container>
    </Flex>
  );
}

const Flex = styled.div<{ $withMargin: boolean }>`
  display: flex;
  justify-content: center;
  margin: ${props => (props.$withMargin ? 'var(--space-4) auto' : 0)};
`;

const Container = styled.div<{ $size: string }>`
  width: ${props => props.$size};
  height: ${props => props.$size};
  position: relative;
`;

const DoubleBounce = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: ${props => props.color || 'var(--primary)'};
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;
  animation: ${() => bounce} 2s infinite ease-in-out;
`;

const DoubleBounce2 = styled(DoubleBounce)`
  animation-delay: -1s;
`;

const bounce = keyframes`
  0%, 100% { 
    transform: scale(0.0);
  }
  50% { 
    transform: scale(1.0);
  }
`;

export default Spinner;
