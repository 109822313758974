import * as React from 'react';
import * as Sentry from '@sentry/react';
import ChunkLoadErrorFallback from './ChunkLoadErrorFallback';
import ErrorFallback from './ErrorFallback';

type Props = {
  children?: React.ReactNode;
};

const GlobalErrorBoundary = ({ children }: Props) => {
  return (
    <Sentry.ErrorBoundary
      fallback={({ error, resetError }) =>
        error instanceof Error && error.name === 'ChunkLoadError' ? (
          <ChunkLoadErrorFallback />
        ) : (
          <ErrorFallback reset={resetError} />
        )
      }
    >
      {children}
    </Sentry.ErrorBoundary>
  );
};

export default GlobalErrorBoundary;
