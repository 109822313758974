import React from 'react';
import { styled } from 'styled-components';
import Card from '../card';
import BoxSkeleton from './BoxSkeleton';
type Props = {
  itemsCount?: number;
};

function SliderSkeleton({ itemsCount = 10 }: Props) {
  return (
    <div>
      <ListTitle />
      <List>
        {Array.from(Array(itemsCount).keys()).map(n => (
          <ListItem key={n}>
            <Paper>
              <Title />
              <Subtitle />
              <Content />
            </Paper>
          </ListItem>
        ))}
      </List>
    </div>
  );
}

const ListTitle = styled(BoxSkeleton)`
  padding: var(--space-3);
  width: 10rem;
`;

const List = styled.ul`
  display: flex;
  overflow: hidden;
  padding: 1.1% 0.5rem;
`;

const ListItem = styled.li`
  flex: 0 0 19.7%;
  text-align: center;
  margin: 0 2px;
`;

const Paper = styled(Card)`
  padding: 0.8rem;
`;

const Title = styled(BoxSkeleton)`
  padding: var(--space-3);
  margin-bottom: 0.5rem;
`;

const Subtitle = styled(BoxSkeleton)`
  padding: var(--space-2);
  margin-bottom: 0.5rem;
`;

const Content = styled(BoxSkeleton)`
  padding: 2rem;
`;

export default SliderSkeleton;
