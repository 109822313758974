import { Student } from '../models';

export const studentDescription = (student: Student): string =>
  [
    student.classe,
    student.denominazioneScuola,
    studentSchoolYear(student),
    student.statoFrequenza,
  ]
    .filter(Boolean)
    .join(' • ');

export const studentA11yDescription = (student: Student): string =>
  [
    student.classe ? `classe ${student.classe}` : '',
    student.denominazioneScuola,
    student.annoScolastico
      ? `anno scolastico ${studentSchoolYear(student)}`
      : '',
  ]
    .filter(Boolean)
    .join(', ');

export const isStudentActive = (student: Student): boolean =>
  student.apiAttive && student.appTutoreStudenteAbilitata;

export const studentDisplayName = ({ nome, cognome }: Student): string =>
  `${nome} ${cognome}`;

export const studentSchoolYear = ({ annoScolastico }: Student): string =>
  `${annoScolastico}/${annoScolastico + 1}`;
