import React from 'react';
import { styled } from 'styled-components';
import AppIcon from '../../../assets/icons/app-icon.svg';
import Menu from '../../../assets/icons/menu.svg';
import useHideNavigation from '../../containers/app/useHideNavigation';
import { useSidebarContext } from '../../context/sidebar.context';
import { BoxSkeleton } from '../skeletons';
import SuperAdminMenu from './SuperAdminMenu';
import { useAppContext } from '../../context/app.context';
import { tablet } from '../../styles/sizes';

const UserMenu = React.lazy(
  () => import(/* webpackChunkName: "user-menu" */ './UserMenu'),
);

const Notifiche = React.lazy(
  () => import(/* webpackChunkName: "notifications" */ './Notifiche'),
);

function Header() {
  const isAdmin = useIsAdmin();
  const notificationsEnabled = useIsNotificationsEnabled();
  const hiddenNavigation = useHideNavigation();
  const [sidebarOpen, setSidebarOpen] = useSidebarContext();
  return (
    <Container>
      {!hiddenNavigation && (
        <MenuBtn
          aria-label={'chiudi/apri menu'}
          aria-expanded={sidebarOpen ? true : undefined}
          aria-controls="navigation"
          onClick={() => setSidebarOpen(open => !open)}
        >
          <Menu width="24" height="24" />
        </MenuBtn>
      )}
      <Logo width="32" height="32" aria-hidden />
      <Title>Nuvola</Title>
      {isAdmin ? <SuperAdminMenu /> : null}
      <Center />
      {notificationsEnabled ? (
        <React.Suspense fallback={<BoxSkeleton />}>
          <Notifiche />
        </React.Suspense>
      ) : null}
      <React.Suspense fallback={<BoxSkeleton />}>
        <UserMenu />
      </React.Suspense>
    </Container>
  );
}

function useIsAdmin() {
  const [{ user }] = useAppContext();
  return Boolean(user?.admin);
}

function useIsNotificationsEnabled() {
  const [{ student, settings }] = useAppContext();
  return Boolean(student && settings?.notifiche.enabled);
}

const Container = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  align-items: center;
  padding: var(--space-2) var(--space-3);
`;

const Logo = styled(AppIcon)`
  color: white;
  background-color: var(--primary);
  padding: var(--space-1);
  border-radius: var(--space-1);
`;

const MenuBtn = styled.button`
  padding: 0;
  margin: 0 var(--space-3) 0 0;
  border: none;
  background: transparent;
  cursor: pointer;
  line-height: 0;

  @media screen and (min-width: ${tablet}) {
    display: none;
  }
`;

const Title = styled.span`
  font-size: x-large;
  font-weight: 500;
  margin: 0 var(--space-3);
`;

const Center = styled.div`
  flex-grow: 1;
`;

export default Header;
