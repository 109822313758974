import React from 'react';
import { styled } from 'styled-components';

type Props = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  color?: keyof typeof Buttons;
  $iconSize?: string;
};

const TextButton = React.forwardRef<HTMLButtonElement, Props>(
  function TextButton(props, ref) {
    const { $iconSize = '0.9rem', color = 'primary', ...buttonProps } = props;
    const ColorTextButton = Buttons[color];
    return (
      <ColorTextButton
        ref={ref}
        $iconSize={$iconSize}
        color={color}
        {...buttonProps}
      />
    );
  },
);

const BaseTextButton = styled.button<{ $iconSize: string }>`
  background: none;
  border: 1px solid transparent;
  align-items: center;
  text-decoration: none;
  font-weight: 600;
  outline: none;
  border-radius: 0.5rem;
  transition: all 0.3s ease-out;
  & svg {
    width: ${({ $iconSize }) => $iconSize};
    margin-right: var(--space-1);
    flex-shrink: 0;
  }
  &:hover:not(:disabled) {
    text-decoration: underline;
    cursor: pointer;
  }
  &:disabled {
    opacity: 0.5;
  }
  padding: var(--space-2) var(--space-3);
`;

const PrimaryTextButton = styled(BaseTextButton)`
  color: var(--primary);
  &:hover:not(:disabled) {
    background-color: var(--primary-container);
  }
  &:focus {
    border: 1px solid var(--primary);
    background-color: var(--primary-container);
  }
  & svg {
    fill: var(--primary);
  }
`;

const SecondaryTextButton = styled(BaseTextButton)`
  color: var(--inactive);
  &:hover:not(:disabled) {
    background-color: var(--inactive) 22;
  }
  &:focus {
    border: 1px solid var(--inactive);
    background-color: var(--inactive) 22;
  }
  & svg {
    fill: var(--inactive);
  }
`;

const ErrorTextButton = styled(BaseTextButton)`
  color: var(--error);
  &:hover:not(:disabled) {
    background-color: var(--error-container);
  }
  &:focus {
    border: 1px solid var(--error);
    background-color: var(--error-container);
  }
  & svg {
    fill: var(--error);
  }
`;

const HighlightTextButton = styled(BaseTextButton)`
  color: var(--highlight);
  &:hover:not(:disabled) {
    background-color: var(--on-highlight);
  }
  &:focus {
    border: 1px solid var(--highlight);
    background-color: var(--on-highlight);
  }
  & svg {
    fill: var(--highlight);
  }
`;

const Buttons = {
  primary: PrimaryTextButton,
  secondary: SecondaryTextButton,
  error: ErrorTextButton,
  highlight: HighlightTextButton,
};

export default TextButton;
