import { styled, keyframes } from 'styled-components';

const moveGradient = keyframes`
    0%{background-position:0 50%}
    50%{background-position:100% 50%}
    100%{background-position:0 50%}
`;

const BoxSkeleton = styled.div`
  background: linear-gradient(-45deg, #eee, #f0f0f0, #f9f9f9);
  background-size: 600% 100%;
  padding: var(--space-3);
  animation: ${moveGradient} 2s linear infinite alternate;
  border-radius: 0.5rem;
`;

export default BoxSkeleton;
