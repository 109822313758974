import React from 'react';
import { styled, keyframes } from 'styled-components';
import Upgrade from '../../../assets/icons/angle-double-up-solid.svg';
import TextButton from '../../components/buttons/TextButton';

function ChunkLoadErrorFallback() {
  return (
    <Center>
      <Upgrade />
      <h2>L&apos;applicazione è stata aggiornata</h2>
      <p>
        Clicca su aggiorna o ricarica la pagina per utilizzare la versione più
        recente.
      </p>
      <UpdateButton
        onClick={() => {
          window.location.reload();
        }}
      >
        Aggiorna
      </UpdateButton>
    </Center>
  );
}

const Center = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: var(--space-4);
  & svg {
    width: 5rem;
    height: 5rem;
    fill: var(--on-primary);
    margin-top: var(--space-4);
    margin-bottom: var(--space-3);
    padding: var(--space-3);
    background-color: var(--primary);
    border-radius: 50%;
  }
  & h2 {
    margin: var(--space-3) 0;
  }
  & p {
    margin-bottom: var(--space-3);
  }
`;
const pulseBackground = () => keyframes`
    0% {
      background: none;
    }
    50% {
      background: var(--primary);
      color: var(--on-primary);
    }
    100% {
      background: none;
    }
`;

const UpdateButton = styled(TextButton)`
  animation: ${() => pulseBackground()} 3s 1s;
`;

export default ChunkLoadErrorFallback;
