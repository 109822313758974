import React from 'react';
import { styled } from 'styled-components';
import { SvgComponent } from '../../models';

type Props = React.HTMLAttributes<HTMLDivElement> & {
  Icon: SvgComponent;
};

const Alert: React.FC<Props> = ({ children, Icon, ...props }) => {
  return (
    <Container role="alert" {...props}>
      <IconWrapper>
        <Icon width="18" height="18" />
      </IconWrapper>
      {children}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: var(--space-2);
  padding: var(--space-3);
  text-align: center;
`;

const IconWrapper = styled.div`
  margin: 0 var(--space-3);
`;

export default Alert;
