import * as React from 'react';
import { NewsDialogContent } from './NewsDialogContent';
import Dialog from '../../../components/dialog';
import { Row } from '../../../components/layout/flexContainers';
import Button from '../../../components/buttons/Button';

type NewsDialogProps = {
  titolo: string;
  contenuto: string;
  isOpen: boolean;
  onDismiss: () => void;
  newsCounter?: string;
};

export function NewsDialog({
  titolo,
  contenuto,
  isOpen,
  newsCounter,
  onDismiss,
}: NewsDialogProps) {
  return (
    <Dialog open={isOpen} aria-labelledby="news-modal">
      <Row $justifyContent="space-between" $alignItems="start">
        <h1>{titolo}</h1>
        {!!newsCounter && newsCounter}
      </Row>
      <NewsDialogContent dangerouslySetInnerHTML={{ __html: contenuto }} />
      <Row $justifyContent="flex-end">
        <Button onClick={onDismiss}>Ho letto</Button>
      </Row>
    </Dialog>
  );
}
