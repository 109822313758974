import { useEffect } from 'react';
import { User } from '../models';
import { Keys } from '../shared-logic/storage';
import { refreshSession } from './client';

const sessionDurationInMilliseconds = 1800000; // 30 minutes

export function useAutoRefreshSession(): void {
  useEffect(() => {
    const intervalId = setInterval(() => {
      refreshSession();
    }, sessionDurationInMilliseconds);
    window.addEventListener('storage', onStorageEvent);
    return () => {
      clearInterval(intervalId);
      window.removeEventListener('storage', onStorageEvent);
    };
  }, []);
}

function onStorageEvent(ev: StorageEvent): void {
  if (ev.key === Keys.user && isChanged(ev.oldValue, ev.newValue)) {
    window.location.reload();
  }
}

function isChanged(oldValue: string | null, newValue: string | null): boolean {
  if (!oldValue || !newValue) return true;

  try {
    const oldUser = JSON.parse(oldValue) as User;
    const newUser = JSON.parse(newValue) as User;
    return oldUser?.id !== newUser?.id;
  } catch {
    return true;
  }
}
