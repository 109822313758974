import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { styled, keyframes } from 'styled-components';
import {
  focusNextElement,
  setVisibilityForScreenReaders,
} from './dialog.utils';
import './dialog.css';
import { tablet } from '../../styles/sizes';

type DialogProps = React.PropsWithChildren<{
  open: boolean;
  onDismiss?: () => void;
  closeOnClickOutside?: boolean;
  className?: string;
  'aria-label'?: string;
  'aria-labelledby'?: string;
  'aria-describedby'?: string;
  role?: 'dialog' | 'alertdialog';
}>;

export const Dialog: React.FC<DialogProps> = ({
  closeOnClickOutside = true,
  onDismiss,
  open = true,
  role = 'dialog',
  ...props
}) => {
  const { container } = useDialog({
    open,
    closeOnClickOutside,
    onDismiss,
  });

  if (!open) {
    return null;
  }

  return ReactDOM.createPortal(
    <DialogOverlay>
      <DialogContent ref={container} role={role} aria-modal="true" {...props} />
    </DialogOverlay>,
    document.body,
  );
};

function useDialog({
  open,
  onDismiss,
  closeOnClickOutside,
}: Pick<DialogProps, 'open' | 'onDismiss' | 'closeOnClickOutside'>) {
  const previousFocus = React.useRef<HTMLElement | null>(null);

  // close on click outside
  const container = React.useRef<HTMLDivElement>(null);

  // close on esc
  React.useEffect(() => {
    const onKeyDown = (e: KeyboardEvent) => {
      if (!open) return;

      switch (e.key) {
        case 'Escape': {
          if (closeOnClickOutside) onDismiss?.();
          break;
        }
        case 'Tab': {
          e.preventDefault();
          focusNextElement(container.current, !e.shiftKey);
          break;
        }
      }
    };

    const onOverlayClick = (e: Event) => {
      if (open && e.target === container.current?.parentNode) onDismiss?.();
    };

    window.addEventListener('keydown', onKeyDown);
    if (closeOnClickOutside) {
      document.body.addEventListener('click', onOverlayClick);
    }
    return () => {
      window.removeEventListener('keydown', onKeyDown);
      if (closeOnClickOutside) {
        document.body.removeEventListener('click', onOverlayClick);
      }
    };
  }, [closeOnClickOutside, onDismiss, open]);

  React.useEffect(() => {
    const dialog = container.current;
    if (!dialog) return;

    const handleScreenReadersVisibility = (visible: boolean) => {
      if (dialog.parentElement) {
        setVisibilityForScreenReaders(dialog.parentElement, visible);
      }
    };
    const resetFocusAndOverflow = () => {
      previousFocus.current?.focus?.();
      previousFocus.current = null;
      resetContentOverflow();
    };

    if (open) {
      previousFocus.current = (document.activeElement as HTMLElement) ?? null;
      focusNextElement(container.current);
      blockContentOverflow();
    } else {
      resetFocusAndOverflow();
    }

    handleScreenReadersVisibility(open);
    return () => {
      handleScreenReadersVisibility(false);
      resetFocusAndOverflow();
    };
  }, [open]);

  return { container };
}

const dialogOverflowClassName = 'overflow-hidden';

function blockContentOverflow() {
  document.body.classList.add(dialogOverflowClassName);
}

function resetContentOverflow() {
  document.body.classList.remove(dialogOverflowClassName);
}

const DialogOverlay = styled.div`
  box-sizing: border-box;
  background-color: rgba(0, 0, 0, 0.6);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  z-index: var(--z-index-backdrop);
`;

const animation = keyframes`
  from {
    opacity: 0;
    transform:translate3d(0, 2rem, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
`;

const DialogContent = styled.div`
  position: relative;
  width: auto;
  border-radius: 0.5rem;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.4);
  z-index: var(--z-index-dialog);
  animation: ${animation} 0.2s;
  background-color: var(--background);
  margin: 10vh var(--space-2);
  padding: var(--space-3);
  @media only screen and (min-width: ${tablet}) {
    width: 50vw;
    margin: 10vh auto;
  }
`;
