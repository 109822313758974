import React, { ReactElement } from 'react';
import { styled } from 'styled-components';
import MenuItem, { ExternalAnchor } from '../menu-item/MenuItem';

import home from '../../../assets/icons/home-solid.svg';
import trophy from '../../../assets/icons/trophy-solid.svg';
import userCheck from '../../../assets/icons/user-check-solid.svg';
import briefCase from '../../../assets/icons/briefcase-solid.svg';
import note from '../../../assets/icons/rate-review-solid.svg';
import book from '../../../assets/icons/book.svg';
import spellCheck from '../../../assets/icons/spell-check-solid.svg';
import document from '../../../assets/icons/file-alt-solid.svg';
import folder from '../../../assets/icons/folder-open-solid.svg';
import web from '../../../assets/icons/web.svg';
import help from '../../../assets/icons/question-circle-solid.svg';
import calendar from '../../../assets/icons/calendar-solid.svg';
import laptopHouse from '../../../assets/icons/laptop-house-solid.svg';
import fax from '../../../assets/icons/fax-solid.svg';
import comments from '../../../assets/icons/comments-solid.svg';
import payments from '../../../assets/icons/money-check-alt-solid.svg';
import fascicolo from '../../../assets/icons/archive-solid.svg';
import elaborato from '../../../assets/icons/history_edu.svg';
import { useAppContext } from '../../context/app.context';
import { AppSettings } from '../../models';
import StudentInfo from '../student-info';
import { isStudente, isTutore } from '../../shared-logic/user';
import storage, { Keys } from '../../shared-logic/storage';

const sections = [
  {
    name: 'Voti',
    path: '/voti',
    icon: trophy,
  },
  {
    name: 'Assenze',
    path: '/assenze',
    icon: userCheck,
  },
  {
    name: 'Note',
    path: '/note',
    icon: note,
  },
  {
    name: 'Argomenti di lezione',
    path: '/argomenti-lezione',
    icon: book,
  },
  {
    name: 'Compiti',
    path: '/compiti',
    icon: spellCheck,
  },
  {
    name: 'Calendario',
    path: '/calendario',
    icon: calendar,
  },
  {
    name: 'Documenti ed Eventi',
    path: '/documenti-eventi',
    icon: folder,
  },
  {
    name: 'Materiale per docente',
    path: '/materiali-per-docente',
    icon: laptopHouse,
  },
  {
    name: 'Elaborato esame',
    path: '/elaborato',
    icon: elaborato,
  },
  {
    name: 'Colloqui',
    path: '/colloqui',
    icon: comments,
  },
  {
    name: 'Documenti scrutinio',
    path: '/documenti-scrutinio',
    icon: document,
  },
  {
    name: 'Bacheche',
    path: '/bacheche',
    icon: web,
  },
  {
    name: 'Questionari',
    path: '/questionari',
    icon: help,
  },
  {
    name: 'Modulistica',
    path: '/modulistica/moduli',
    icon: fax,
  },
  {
    name: 'Pagamenti',
    path: '/pagamenti',
    icon: payments,
  },
  {
    name: 'Fascicolo',
    path: '/documenti-fascicolo',
    icon: fascicolo,
  },
  { name: 'StudyAway', path: '/studyaway' },
  {
    name: 'PCTO',
    path: '/pcto',
    icon: briefCase,
  },
];

function getEnabledSections(settings: Partial<AppSettings> = {}) {
  const {
    voti,
    assenze,
    note,
    argomenti,
    scrutinio,
    eventi,
    questionari,
    materialePerDocente,
    colloqui,
    modulistica,
    pagamenti,
    fascicolo,
    elaborato,
    docsity,
    pcto,
  } = settings;
  return sections.filter(section => {
    switch (section.name) {
      case 'Voti':
        return voti?.enabled;
      case 'Assenze':
        return assenze?.enabled;
      case 'Note':
        return note?.enabled;
      case 'Argomenti di lezione':
        return argomenti?.lessonsEnabled;
      case 'Compiti':
        return argomenti?.homeworksEnabled;
      case 'Calendario':
        return eventi?.classEventsEnabled;
      case 'Documenti ed Eventi':
        return (
          eventi?.classEventsEnabled ||
          eventi?.subjectEventsEnabled ||
          eventi?.studentEventsEnabled
        );
      case 'Documenti scrutinio':
        return scrutinio?.documentsEnabled;
      case 'Bacheche':
        return true;
      case 'Questionari':
        return questionari?.enabled;
      case 'Materiale per docente':
        return materialePerDocente?.enabled;
      case 'Modulistica':
        return modulistica?.enabled;
      case 'Colloqui':
        return colloqui?.enabled;
      case 'Pagamenti':
        return pagamenti?.enabled;
      case 'Fascicolo':
        return fascicolo?.enabled;
      case 'Elaborato esame':
        return elaborato?.enabled;
      case 'StudyAway':
        return docsity?.enabled;
      case 'PCTO':
        return pcto?.enabled;
      default:
        return false;
    }
  });
}

function RootSidebar(): ReactElement {
  const [{ user, settings }] = useAppContext();
  const enabledSections = getEnabledSections(settings);
  const enabledAreas =
    user?.aree?.filter(a => {
      if (isTutore()) {
        return a.ruolo && a.ruolo !== 'tutore';
      }
      if (isStudente()) {
        return a.ruolo && a.ruolo !== 'alunno';
      }

      return a;
    }) || [];

  return (
    <Container>
      <StudentInfo />
      <MenuItem name="Home" path="/" Icon={home} />
      {enabledSections.map(({ name, path, icon }) => (
        <MenuItem key={name} name={name} path={path} Icon={icon} />
      ))}
      {enabledAreas.length > 0 && (
        <>
          <Divider />
          {enabledAreas.map(area => (
            <ExternalAnchor
              key={area.ruolo}
              href={area.link}
              onClick={() => {
                storage.remove(Keys.student);
                storage.remove(Keys.students);
              }}
            >
              {area.nome}
            </ExternalAnchor>
          ))}
        </>
      )}
    </Container>
  );
}

const Divider = styled.div`
  height: 1px;
  background-color: var(--outline);
  margin: 0 var(--space-3) var(--space-2) var(--space-3);
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

export default RootSidebar;
