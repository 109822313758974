export function initials(text?: string | null): string {
  const name = text && text.trim();
  if (!name) return '';

  return name
    .replace(/[^A-Za-z ]/g, '')
    .split(' ')
    .filter(chunck => chunck)
    .filter((_, index, array) => index === 0 || index === array.length - 1)
    .map(chunck => chunck.charAt(0).toUpperCase())
    .join('');
}

export function hashCode(str: string): number {
  let hash = 0;
  for (let i = 0; i < str.length; i += 1) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  return hash;
}

export const capitalizeFirstLetter = (text?: string): string | undefined =>
  text && text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();

export const maybeTextWithPrefix =
  (prefix: string) =>
  (text?: string): string | '' =>
    text ? `${prefix}${text}` : '';

export const slugify = (string: string): string =>
  normalizeCharacters(string.toLowerCase())
    .split(' ')
    .filter(x => x)
    .join('-');

function normalizeCharacters(word: string): string {
  return specialCharMap
    .reduce<string>((acc, [specialChar, normalizedChar]) => {
      return acc.replace(new RegExp(specialChar, 'g'), normalizedChar);
    }, word)
    .replace(/[^a-z0-9 -]/g, '');
}

const specialCharMap = [
  ['à', 'a'],
  ['á', 'a'],
  ['ã', 'a'],
  ['ä', 'a'],
  ['â', 'a'],
  ['è', 'e'],
  ['é', 'e'],
  ['ë', 'e'],
  ['ê', 'e'],
  ['ì', 'i'],
  ['í', 'i'],
  ['ï', 'i'],
  ['î', 'i'],
  ['ò', 'o'],
  ['ó', 'o'],
  ['ö', 'o'],
  ['ô', 'o'],
  ['ù', 'u'],
  ['ú', 'u'],
  ['ü', 'u'],
  ['û', 'u'],
  ['ñ', 'n'],
  ['ç', 'c'],
  ['·', '-'],
  ['/', '-'],
  ['_', '-'],
  [',', '-'],
  [':', '-'],
  [';', '-'],
];

export function ellipsize(str: string, maxLength: number): string {
  if (str.length <= maxLength) {
    return str;
  }
  return str.slice(0, maxLength) + '...';
}

export function shortId(length = 8) {
  // The order of characters is optimized for better gzip and brotli compression.
  const characters =
    'useandom-26T198340PX75pxJACKVERYMINDBUSHWOLF_GQZbfghjklqvwyzrict';
  let id = '';
  let i = length;
  while (i--) {
    id += characters[(Math.random() * characters.length) | 0];
  }
  return id;
}
