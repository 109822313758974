import React from 'react';
import { useLocation } from 'react-router-dom';
import RootSidebar from './RootSidebar';
import ModulisticaSidebar from './ModulisticaSidebar';

const sidebars: { [key: string]: React.FC } = {
  modulistica: ModulisticaSidebar,
};

function Sidebar(): React.ReactElement {
  const { pathname } = useLocation();
  const sidebarKey = Object.keys(sidebars).find(key =>
    pathname.startsWith(`/${key}`),
  );
  const RouteSidebar = sidebarKey ? sidebars[sidebarKey] : RootSidebar;
  return <RouteSidebar />;
}

export default Sidebar;
