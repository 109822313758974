import { styled } from 'styled-components';

const Card = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 1rem;
  margin: var(--space-2);
  background-color: var(--surface);
  box-shadow: var(--shadow-elevation-medium);
`;

export default Card;
