import { useLocation } from 'react-router-dom';

function useHideNavigation(): boolean {
  const { pathname } = useLocation();
  return hideSidebarRoutes.includes(pathname.substring(1));
}

const hideSidebarRoutes = ['alunni'];

export default useHideNavigation;
