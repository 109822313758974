import * as React from 'react';
import storage, { Keys } from '../shared-logic/storage';
import { AppSettings, User, Student } from '../models';

export type AppState = {
  user?: User;
  student?: Student;
  students?: Student[];
  settings?: AppSettings;
};

type AppAction =
  | { type: 'clear-context' }
  | { type: 'set-context'; value: AppState }
  | { type: 'set-user'; user: User }
  | { type: 'set-students'; students: Student[] }
  | { type: 'set-student'; student: Student }
  | { type: 'set-institute-settings'; settings: AppSettings };

type AppContextType = [AppState, React.Dispatch<AppAction>];

const AppContext = React.createContext<AppContextType | null>(null);

export const AppContextProvider = ({
  children,
}: {
  children?: React.ReactNode;
}) => {
  const initialState = React.useMemo(() => restoreAppContext(), []);
  const [state, dispatch] = React.useReducer(reducer, initialState);
  return (
    <AppContext.Provider value={[state, dispatch]}>
      {children}
    </AppContext.Provider>
  );
};

export const TestAppContextProvider = ({
  initialState,
  children,
}: React.PropsWithChildren<{ initialState?: AppState }>) => {
  const [state, dispatch] = React.useReducer(reducer, initialState || {});
  return (
    <AppContext.Provider value={[state, dispatch]}>
      {children}
    </AppContext.Provider>
  );
};

export function useAppContext(): AppContextType {
  const appContext = React.useContext(AppContext);
  if (!appContext) {
    throw new Error('useAppContext must be used within a AppProvider');
  }
  return appContext;
}

export function useStudentId() {
  const [{ student }] = useAppContext();
  if (!student) {
    throw new Error('Student ID must be set');
  }
  return student.id;
}

function restoreAppContext(): AppState {
  const user = storage.getObject<User>(Keys.user) || undefined;
  const expiry = storage.getExpiration();
  const expired = expiry && new Date() > expiry;
  const appContext: AppState = { user };
  if (!expired) {
    appContext.students =
      storage.getObject<Student[]>(Keys.students) || undefined;
    appContext.student = storage.getObject<Student>(Keys.student) || undefined;
    appContext.settings =
      storage.getObject<AppSettings>(Keys.settings) || undefined;
  }
  return appContext;
}

function reducer(state: AppState, action: AppAction): AppState {
  switch (action.type) {
    case 'set-context':
      return action.value;
    case 'set-user':
      return {
        ...state,
        user: action.user,
      };
    case 'set-students':
      return {
        ...state,
        students: action.students,
      };
    case 'set-student':
      return {
        ...state,
        student: action.student,
      };
    case 'set-institute-settings':
      return {
        ...state,
        settings: action.settings,
      };
    case 'clear-context':
      return {};
    default:
      return state;
  }
}

export default AppContext;
