import React from 'react';
import { styled } from 'styled-components';
import BoxSkeleton from './BoxSkeleton';
import { Paper } from '../paper/Paper';

type DetailSkeletonProps = { noCard?: boolean };

function DetailSkeleton({ noCard }: DetailSkeletonProps) {
  const numberOfRows = 4;
  const Wrapper = noCard ? React.Fragment : Paper;
  return (
    <Wrapper>
      <Title />
      <SubTitle />
      <ul>
        {Array.from(Array(numberOfRows).keys()).map(key => (
          <li key={key}>
            <ListItem />
          </li>
        ))}
      </ul>
    </Wrapper>
  );
}

const Title = styled(BoxSkeleton)`
  width: 75%;
`;

const SubTitle = styled(BoxSkeleton)`
  padding: 0;
  height: 1rem;
  margin-top: var(--space-2);
  margin-bottom: var(--space-3);
`;

const ListItem = styled(BoxSkeleton)`
  margin-top: var(--space-2);
  height: 3rem;
`;

export default DetailSkeleton;
