import { apiPostFormData } from './client';
import { User } from '../models';

export interface EditPasswordParams {
  password: string;
  newPassword: string;
  confirmNewPassword: string;
}

export interface EditExpiredPasswordParams extends EditPasswordParams {
  username: string;
}

export interface EditPasswordResponse {
  dataScadenza: string;
}

export function signIn(username: string, password: string): Promise<User> {
  return apiPostFormData('/login', {
    username,
    password,
    applicazione: 'WEB',
  });
}

export const editPassword: (
  params: EditPasswordParams,
) => Promise<EditPasswordResponse> = ({
  password,
  newPassword,
  confirmNewPassword,
}) =>
  apiPostFormData('/cambia-password', {
    password_vecchia: password,
    password_nuova: newPassword,
    password_nuova_conferma: confirmNewPassword,
  });

export const editExpiredPassword: (
  params: EditExpiredPasswordParams,
) => Promise<EditPasswordResponse> = ({
  username,
  password,
  newPassword,
  confirmNewPassword,
}) =>
  apiPostFormData('/login/cambia-password-forzato', {
    username,
    password_vecchia: password,
    password_nuova: newPassword,
    password_nuova_conferma: confirmNewPassword,
  });
