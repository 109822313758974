import React from 'react';
import { styled } from 'styled-components';
import { initials } from '../../shared-logic/text';
import { randomColor } from '../../styles/colorUtils';

export interface AvatarProps {
  src?: string;
  name: string;
  size?: string;
  ariaHidden?: boolean;
}

function Avatar({
  src,
  name,
  size = '48px',
  ariaHidden = false,
}: AvatarProps): React.ReactElement {
  return (
    <Container $name={name} $size={size} aria-hidden={ariaHidden}>
      {src ? (
        <img src={src} alt={`Avatar di ${name}`} width={size} height={size} />
      ) : (
        <Initials $size={size}>{initials(name)}</Initials>
      )}
    </Container>
  );
}

const Container = styled.div<{ $name: string; $size: string }>`
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  background-color: ${props => randomColor(props.$name)};
  width: ${props => props.$size};
  height: ${props => props.$size};
`;

const Initials = styled.span<{ $size: string }>`
  text-align: center;
  color: white;
  font-size: ${props => `calc(${props.$size} * .45)`};
  font-weight: 600;
`;

export default Avatar;
