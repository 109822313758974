import * as React from 'react';
import { styled } from 'styled-components';
import Alert from './Alert';
import Error from '../../../assets/icons/exclamation-circle.svg';

type Props = React.PropsWithChildren<{
  className?: string;
}>;

const ErrorAlert = ({ children, className }: Props) => {
  return (
    <AlertBox className={className} Icon={Icon}>
      {children}
    </AlertBox>
  );
};

const AlertBox = styled(Alert)`
  background-color: var(--error-container);
  color: var(--error);
`;

const Icon = styled(Error)`
  fill: var(--error);
`;

export default ErrorAlert;
