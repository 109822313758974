import React, { FunctionComponent, ReactElement, SVGProps } from 'react';
import { styled, css } from 'styled-components';
import { Link, useLocation } from 'react-router-dom';

type Props = {
  name: string;
  path: string;
  className?: string;
  Icon?: FunctionComponent<SVGProps<SVGSVGElement> & { title?: string }>;
};

const MenuItem = ({
  name,
  path,
  className,
  Icon = undefined,
}: Props): ReactElement => {
  const { pathname } = useLocation();
  const current = isCurrentRoute(pathname, removeQueryString(path));
  return (
    <Anchor
      to={path}
      $current={current ? 1 : 0}
      aria-current={current ? 'page' : undefined}
      className={className}
    >
      {Icon && <Icon aria-hidden="true" />}
      {name}
    </Anchor>
  );
};

function isCurrentRoute(currentRoute: string, route: string): boolean {
  return (
    (route === '/' && currentRoute === route) ||
    (route !== '/' && currentRoute.startsWith(route))
  );
}

function removeQueryString(path: string) {
  return path.split('?')[0];
}

const menuItemStyle = css`
  display: flex;
  align-items: center;
  border-radius: 8px;
  min-height: 32px;
  text-decoration: none;
  outline: none;
  font-weight: 500;
  border: 1px solid transparent;
  padding: 0 var(--space-2);
  margin: 0 var(--space-2) var(--space-2);
  color: var(--on-background);
  &:hover {
    background-color: var(--primary-container);
  }
  &:focus {
    background-color: var(--primary-container);
    border: 1px solid var(--primary);
    color: var(--primary);
  }
  & > svg {
    width: 18px;
    height: 18px;
    flex-shrink: 0;
    fill: currentColor;
    margin-right: var(--space-3);
  }
  &:focus > svg {
    fill: var(--primary);
  }
`;

type AnchorProps = {
  $current: 0 | 1;
};

const Anchor = styled(Link)<AnchorProps>`
  ${menuItemStyle};
  ${({ $current }) =>
    $current &&
    css`
      color: var(--primary);
      background-color: var(--primary-container);
      & > svg {
        fill: var(--primary);
      }
      &:hover,
      :focus {
        background-color: var(--primary-container);
      }
    `};
`;

export const ExternalAnchor = styled.a`
  ${menuItemStyle};
`;

export default MenuItem;
