import React, { ReactElement, ReactNode } from 'react';
import { styled, css } from 'styled-components';
import Sidebar from '../../components/sidebar/Sidebar';
import Header from '../../components/header/Header';
import ArrowLeft from '../../../assets/icons/arrow-left.svg';
import ArrowRight from '../../../assets/icons/arrow-right.svg';
import useHideNavigation from './useHideNavigation';
import { useLocation } from 'react-router-dom';

import {
  SidebarContextProvider,
  useCloseSidebarAfterNavigationOnMobile,
  useSetSidebarInitiallyOpenOnDesktop,
  useSidebarContext,
} from '../../context/sidebar.context';
import { SnackbarContextProvider } from '../../context/snackbar.context';
import { tablet } from '../../styles/sizes';

type Props = { children: ReactNode };

function AppLayout({ children }: Props): ReactElement {
  return (
    <Container>
      <SnackbarContextProvider>
        <SidebarContextProvider>
          <HeaderContainer>
            <Header />
          </HeaderContainer>
          <Nav>
            <Sidebar />
          </Nav>
          <Content>{children}</Content>
        </SidebarContextProvider>
      </SnackbarContextProvider>
    </Container>
  );
}

type NavProps = { children: React.ReactNode };

const Nav: React.FC<NavProps> = ({ children }) => {
  const [open, setOpen] = useSidebarContext();
  const { pathname } = useLocation();
  useSetSidebarInitiallyOpenOnDesktop();
  useCloseSidebarAfterNavigationOnMobile(pathname);
  const hiddenNavigation = useHideNavigation();
  if (hiddenNavigation) {
    return null;
  }

  return (
    <SidebarPlaceHolder $open={open}>
      <SidebarContainer id="navigation">
        <SidebarSurface>{open && children}</SidebarSurface>
        <Toggle
          aria-label={'mostra/nascondi menu'}
          aria-expanded={open ? true : undefined}
          aria-controls="navigation"
          $open={open}
          onClick={() => setOpen(!open)}
        >
          {open ? (
            <ArrowLeft width={10} height={14} style={{ marginRight: '1px' }} />
          ) : (
            <ArrowRight width={10} height={14} style={{ marginLeft: '1px' }} />
          )}
        </Toggle>
      </SidebarContainer>
    </SidebarPlaceHolder>
  );
};

type ContentProps = { children: ReactNode };

const Content: React.FC<ContentProps> = ({ children }) => {
  const hiddenNavigation = useHideNavigation();
  const [sidebarOpen] = useSidebarContext();
  const sidebarVisible = sidebarOpen && !hiddenNavigation;
  return <MainContent $sidebarVisible={sidebarVisible}>{children}</MainContent>;
};

const Container = styled.div`
  background-color: var(--background);
  padding-top: var(--headerHeight);
  display: flex;
`;

const HeaderContainer = styled.header`
  height: var(--headerHeight);
  background-color: var(--surface);
  box-shadow: var(--shadow-elevation-medium);
  z-index: var(--z-index-header);
  width: 100%;
  display: flex;
  align-items: center;
  position: fixed;
  top: 0;

  @media print {
    display: none;
  }
`;

const SidebarPlaceHolder = styled.div<{ $open: boolean }>`
  width: ${({ $open }) =>
    $open ? 'var(--sidebarWidth)' : 'var(--collapsedSidebarWidth)'};
  padding-right: calc(var(--sidebarToggleSize) / 2);
  @media screen and (max-width: ${tablet}) {
    width: ${({ $open }) => ($open ? '100%' : 0)};
    padding-right: 0;
  }
`;

const SidebarContainer = styled.nav`
  position: fixed;
  height: calc(100% - var(--headerHeight));
  padding-right: inherit;
  top: var(--headerHeight);
  bottom: 0;
  display: flex;
  flex-direction: column;
  width: inherit;
  transition: width 300ms cubic-bezier(0.2, 0, 0, 1) 0s;
`;

const SidebarSurface = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-top: var(--space-3);
  background-color: var(--surface);
  box-shadow: var(--shadow-elevation-medium);
  overflow-y: auto;
`;

const Toggle = styled.button<{ $open: boolean }>`
  display: none;
  background-color: var(--surface);
  border: 1px solid var(--inactive);
  position: absolute;
  top: var(--space-5);
  right: 0;
  width: var(--sidebarToggleSize);
  height: var(--sidebarToggleSize);
  border-radius: 50%;
  cursor: pointer;
  line-height: 0;
  transition: opacity 200ms ease;
  opacity: 1;

  &:hover {
    background-color: var(--primary-container);
  }

  @media screen and (min-width: ${tablet}) {
    display: block;
    opacity: ${props => (props.$open ? 0 : 1)};
    ${SidebarContainer}:hover & {
      opacity: 1;
    }
    &:focus {
      opacity: 1;
    }
  }
`;

const MainContent = styled.main<{ $sidebarVisible: boolean }>`
  flex: 1;
  overflow-x: auto;
  ${({ $sidebarVisible }) => css`
    min-height: calc(100vh - var(--headerHeight));
    display: ${$sidebarVisible ? 'none' : 'block'};
    @media screen and (min-width: ${tablet}) {
      display: block;
    }
    @media print {
      display: block;
    }
  `};
`;

export default AppLayout;
