import React, { useState, useRef, useEffect } from 'react';
import { styled, css } from 'styled-components';
import Dialog from '../dialog';
import Binoculars from '../../../assets/icons/binoculars-solid.svg';
import Close from '../../../assets/icons/times-solid.svg';
import Copy from '../../../assets/icons/copy-solid.svg';
import { useAppContext } from '../../context/app.context';
import { copyTextToClipboard } from '../../shared-logic/clipboard';
import { useSnackbarContext } from '../../context/snackbar.context';
import IconButton from '../buttons/IconButton';
import { fetchDatiSuperAdmin } from '../../api/datiSuperAdmin';
import { DatiSuperAdmin } from '../../models';
import WarningAlert from '../alerts/WarningAlert';
import ErrorAlert from '../alerts/ErrorAlert';

function SuperAdminMenu() {
  const [open, setOpen] = useState(false);

  return (
    <>
      <MenuButton
        $open={open}
        title="Mostra informazioni super admin"
        aria-label="Mostra informazioni super admin"
        onClick={() => setOpen(true)}
      >
        <Binoculars />
      </MenuButton>
      <Dialog
        open={open}
        aria-labelledby="super-admin-data-title"
        onDismiss={() => setOpen(false)}
      >
        {open ? <Dati onClose={() => setOpen(false)} /> : null}
      </Dialog>
    </>
  );
}

function Dati({ onClose }: { onClose: () => void }) {
  const content = useRef<HTMLDListElement>(null);
  const [{ user, student, settings }] = useAppContext();
  const studentId = student?.id;
  const [, setMessage] = useSnackbarContext();
  const [data, setData] = useState<DatiSuperAdmin>();
  const [error, setError] = useState<Error | null>(null);
  const [loading, setLoading] = useState(true);

  const copyToClipboard = async () => {
    const info = content.current?.innerText.replace(/\n(.*\n?)/g, ': $1') || '';
    try {
      await copyTextToClipboard(info);
      setMessage('Dati copiati!');
    } catch (err) {
      setMessage(
        'Impossibile copiare i dati automaticamente. Seleziona il testo e copia manualmente.',
      );
    }
  };

  useEffect(() => {
    fetchDatiSuperAdmin(studentId)
      .then(res => setData(res.dettaglio))
      .catch(err => setError(err))
      .finally(() => setLoading(false));
  }, [studentId]);

  return (
    <Content>
      <Row>
        <h2 id="super-admin-data-title">Dati super admin</h2>
        <Row>
          <IconButton aria-label="copia" onClick={copyToClipboard}>
            <Copy />
          </IconButton>
          <IconButton aria-label="chiudi" onClick={onClose}>
            <Close />
          </IconButton>
        </Row>
      </Row>
      <div>
        {loading ? (
          <WarningAlert>
            Attendere il caricamento dei dati completi dal server
          </WarningAlert>
        ) : null}
        {error ? (
          <ErrorAlert>
            <p>
              Errore: la chiamata al server non è andata a buon fine. Il campo{' '}
              <strong>Informazioni tecniche</strong> non è stato valorizzato.
            </p>
          </ErrorAlert>
        ) : null}
        <p>
          <Dl ref={content} aria-labelledby="super-admin-data-title">
            <dt>URL</dt>
            <dd>
              <a href={window.location.href}>{window.location.href}</a>
            </dd>
            {data &&
              Object.entries(data).map(([key, value]) => (
                <React.Fragment key={key}>
                  <dt>{key}</dt>
                  <dd>{value}</dd>
                </React.Fragment>
              ))}
          </Dl>
        </p>
        <br />
        <H3>Ulteriori informazioni</H3>
        <p>
          <details>
            <summary>Utente</summary>
            <Code>{JSON.stringify(user, null, 2)}</Code>
          </details>
        </p>
        <p>
          <details>
            <summary>Studente</summary>
            <Code>{JSON.stringify(student, null, 2)}</Code>
          </details>
        </p>
        <p>
          <details>
            <summary>Impostazioni istituto</summary>
            <Code>{JSON.stringify(settings, null, 2)}</Code>
          </details>
        </p>
      </div>
    </Content>
  );
}

const MenuButton = styled.button<{ $open?: boolean }>`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  transition: background-color 0.2s ease;
  & > svg {
    width: 1.2rem;
    height: 1.2rem;
    transition: fill 0.2s ease;
  }
  padding: 0 var(--space-2);
  ${({ $open }) =>
    $open
      ? css`
          background-color: var(--secondary);
          & > svg {
            fill: var(--on-primary);
          }
        `
      : css`
          background-color: var(--primary-container);
          & > svg {
            fill: var(--inactive);
          }
          &:hover {
            background-color: var(--outline);
          }
        `};
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

const Dl = styled.dl`
  display: grid;
  grid-template-columns: auto 1fr;
  & > dt {
    font-weight: 600;
    text-transform: capitalize;
  }
  & > dt,
  > dd {
    padding: var(--space-2);
  }
  & > dt:nth-of-type(even),
  > dd:nth-of-type(even) {
    background: var(--background);
  }
  & > dt::after {
    content: ':';
  }
`;

const H3 = styled.h3`
  margin: var(--space-2) 0 var(--space-3) 0;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: var(--space-3);
`;

const Code = styled.pre`
  font-family: 'Courier New', Courier, monospace;
  border-radius: 0.5rem;
  overflow-x: auto;
  overflow-y: hidden;
  background-color: var(--on-background);
  color: var(--surface);
  padding: var(--space-2) 0;
`;

export default SuperAdminMenu;
