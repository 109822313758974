import * as React from 'react';
import { styled } from 'styled-components';
import Copy from '../../../assets/icons/copy-solid.svg';

import Card from '../../components/card';
import IconButton from '../../components/buttons/IconButton';
import { useAppContext } from '../../context/app.context';
import { useSnackbarContext } from '../../context/snackbar.context';
import { copyTextToClipboard } from '../../shared-logic/clipboard';

function Informazioni() {
  const [{ student, user }] = useAppContext();
  const [, setMessage] = useSnackbarContext();
  const username = user?.username || 'Non disponibile';
  const istituto = student?.denominazioneScuola || 'Non disponibile';
  const userAgent = navigator?.userAgent || 'Non disponibile';

  const copyToClipboard = async () => {
    const info = `Username: ${username} - Istituto: ${istituto} - Browser: ${userAgent}`;
    try {
      await copyTextToClipboard(info);
      setMessage('Dati copiati!');
    } catch (err) {
      setMessage(
        'Impossibile copiare i dati automaticamente. Seleziona il testo e copia manualmente.',
      );
    }
  };

  return (
    <div>
      <Title>Informazioni</Title>
      <Container>
        <Right>
          <IconButton aria-label="copia" onClick={copyToClipboard}>
            <Copy />
          </IconButton>
        </Right>
        <Info>
          <strong>Username: </strong>
          {username}
        </Info>
        <Info>
          <strong>Istituto: </strong>
          {istituto}
        </Info>
        <Info>
          <strong>Browser: </strong>
          {userAgent}
        </Info>
      </Container>
    </div>
  );
}

const Title = styled.h1`
  margin: var(--space-3);
`;

const Container = styled(Card)`
  padding: var(--space-3);
`;

const Info = styled.p`
  margin: var(--space-2);
`;

const Right = styled.div`
  align-self: flex-end;
`;

export default Informazioni;
