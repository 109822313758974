import React, { useEffect } from 'react';
import * as Sentry from '@sentry/react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';

type SentrySession = {
  username: string;
  studentId: number;
  codiceCliente: string;
};

const SENTRY_ENABLED =
  process.env.NODE_ENV === 'production' &&
  window.origin.endsWith('madisoft.it');

const SAMPLE_RATE = 0.001;

export function initSentry() {
  if (!SENTRY_ENABLED) return;

  Sentry.init({
    dsn: 'https://d7c16e642291478dfd62875b47492c76@sentry.ops.madisoft.it/5',
    environment: process.env.NODE_ENV,
    release: process.env.RELEASE_NAME,
    tracesSampleRate: SAMPLE_RATE,
    beforeSend: ev => {
      if (shouldSkipException(ev)) {
        return null;
      }
      return ev;
    },
    denyUrls: [
      /file:\/\/\//,
      /blob:https\/\//,
      // Google Adsense
      /pagead\/js/i,
      // Facebook flakiness
      /graph\.facebook\.com/i,
      // Facebook blocked
      /connect\.facebook\.net\/en_US\/all\.js/i,
      // Woopra flakiness
      /eatdifferent\.com\.woopra-ns\.com/i,
      /static\.woopra\.com\/js\/woopra\.js/i,
      // Chrome extensions
      /extensions\//i,
      /^chrome:\/\//i,
      /^chrome-extension:\/\//i,
      // Other plugins
      /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
      /webappstoolbarba\.texthelp\.com\//i,
      /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
    ],
    ignoreErrors: [
      // Random plugins/extensions
      'top.GLOBALS',
      // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
      'originalCreateNotification',
      'canvas.contentDocument',
      'MyApp_RemoveAllHighlights',
      'http://tt.epicplay.com',
      "Can't find variable: ZiteReader",
      'jigsaw is not defined',
      'ComboSearch is not defined',
      'http://loading.retry.widdit.com/',
      'atomicFindClose',
      // Facebook borked
      'fb_xd_fragment',
      // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to reduce this. (thanks @acdha)
      // See http://stackoverflow.com/questions/4113268/how-to-stop-javascript-injection-from-vodafone-proxy
      'bmi_SafeAddOnload',
      'EBCallBackMessageReceived',
      // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
      'conduitPage',
      // Generic error code from errors outside the security sandbox
      // You can delete this if using raven.js > 1.0, which ignores these automatically.
      'Script error.',
      // Avast extension error
      '_avast_submit',
      'ChunkLoadError',
      'ResizeObserver loop limit exceeded',
      'SecurityError: The operation is insecure.',
      /NotFoundError: Failed to execute '(insertBefore|removeChild)' on 'Node'/,
      /NotFoundError: The object can not be found here/,
      /instantSearchSDKJSBridgeClearHighlight/,
      /Unexpected token 'else'/,
      /Identifier 'change_ua' has already been declared/,
      /Non-Error promise rejection captured with value: undefined/,
      /Cannot read property 'postMessage' of null/,
      'createRoot(...): Target container is not a DOM element.',
      /div:has\(> iframe\[id='198230182308109283091823098102938908128390']\)/,
      /undefined is not an object \(evaluating 'a\.[MNOL]'\)/,
      'Permission denied to access property "unlock"',
      /Cannot redefine property: googletag/,
    ],
    autoSessionTracking: true,
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
    ],
  });
}

export function useSentryContext({
  username,
  studentId,
  codiceCliente,
}: Partial<SentrySession>) {
  React.useEffect(() => {
    Sentry.setUser({ username });
    Sentry.setTag('codiceCliente', codiceCliente || '');
    Sentry.setContext('session', {
      codiceCliente,
      studentId,
    });
  }, [studentId, codiceCliente, username]);
}

function shouldSkipException(ev: Sentry.Event): boolean {
  return sentryEventFitlers.some(shouldSkip => shouldSkip(ev));
}

const sentryEventFitlers = [isFirefoxIOSBug, isFromLocalFile];

function isFirefoxIOSBug(ev: Sentry.Event): boolean {
  return (
    ev.contexts?.browser?.name === 'Firefox iOS' &&
    ev.message === "TypeError: null is not an object (evaluating 'a.title')"
  );
}

function isFromLocalFile(ev: Sentry.Event): boolean {
  return !!ev.request?.url?.match(/file:\/\/\//i);
}
