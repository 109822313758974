import React, { forwardRef } from 'react';
import { styled } from 'styled-components';
import InputErrors from '../InputErrors';
import Hint from '../Hint';

type Props = {
  onChange: (value: string) => void;
  label: string;
  value: string;
  name?: string;
  placeholder?: string;
  required?: boolean;
  maxLength?: number;
  secured?: boolean;
  autoComplete?: string;
  className?: string;
  errors?: string[];
  hint?: string;
};

type Ref =
  | ((instance: HTMLInputElement | null) => void)
  | React.MutableRefObject<HTMLInputElement | null>
  | null;

const TextInput = forwardRef(function TextInput(props: Props, ref: Ref) {
  const {
    label,
    value,
    placeholder,
    required,
    maxLength,
    secured,
    onChange,
    autoComplete,
    className,
    errors = [],
    hint,
  } = props;
  return (
    <Box className={className}>
      <Label>
        <Input
          ref={ref}
          type={secured ? 'password' : 'text'}
          value={value}
          placeholder={placeholder}
          required={required}
          maxLength={maxLength}
          aria-errormessage={errors.join(',')}
          $hasError={errors.length > 0}
          autoComplete={autoComplete}
          onChange={e => onChange(e.target.value)}
        />
        <FloatingLabel>
          {label}
          {required ? '*' : ''}
        </FloatingLabel>
      </Label>
      {hint && <Hint>{hint}</Hint>}
      <InputErrors errors={errors} />
    </Box>
  );
});

const Box = styled.div`
  margin-bottom: var(--space-3);
`;

const Label = styled.label`
  display: flex;
  flex-direction: column;
  position: relative;
  padding-top: 1.5rem;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  transition: background-color 0.2s ease-out;
  &:hover {
    background-color: var(--primary-container);
  }
  &:focus-within {
    background-color: var(--primary-container);
  }
`;

const Input = styled.input<{ $hasError: boolean }>`
  border: none;
  background: none;
  outline: none;
  width: 100%;
  padding: var(--space-2);
  border-bottom: 2px solid
    ${({ $hasError }) => ($hasError ? 'var(--error)' : 'var(--outline)')};
  transition: border-bottom-color 0.2s ease-out;

  &::placeholder {
    opacity: 0;
    color: var(--inactive);
    transition: opacity 0.2s ease-out;
  }

  &:focus {
    border-bottom: 2px solid var(--secondary);
  }

  &:focus::placeholder {
    opacity: 1;
  }
`;

const FloatingLabel = styled.span`
  position: absolute;
  top: 1.4rem;
  transition:
    top 0.2s ease-out,
    font-size 0.2s ease-out,
    font-weight 0.2s ease-out;
  margin: var(--space-2);

  ${Input}:focus ~ &,
  ${Input}:not([value='']) ~ & {
    font-size: var(--font-size-regular);
    font-weight: 700;
    top: 0;
  }
`;

export default TextInput;
