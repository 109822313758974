import React, { useState } from 'react';
import { styled } from 'styled-components';

import MenuItem, { ExternalAnchor } from '../menu-item/MenuItem';
import RootSidebar from './RootSidebar';
import BackIcon from '../../../assets/icons/arrow-circle-left.svg';

function ModulisticaSidebar(): React.ReactElement {
  const [back, setBack] = useState(false);
  if (back) {
    return <RootSidebar />;
  }
  return (
    <Container>
      <BackButton as="button" onClick={() => setBack(true)}>
        <BackIcon width="18px" height="18px" />
        <span>Menu principale</span>
      </BackButton>
      <Divider />
      <MenuItem name="Moduli" path="/modulistica/moduli" />
      <MenuItem
        name="Moduli compilati"
        path="/modulistica/compilati?compilati=true&bozze=true"
      />
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
`;

const BackButton = styled(ExternalAnchor)`
  cursor: pointer;
`;

const Divider = styled.div`
  height: 1px;
  border-bottom: 1px solid;
  margin: 0 var(--space-2) var(--space-2);
`;

export default ModulisticaSidebar;
