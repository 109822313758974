import React, { ReactElement, MouseEventHandler } from 'react';
import { styled } from 'styled-components';
import CloudShowers from '../../../assets/icons/cloud-showers-heavy-solid.svg';
import { Link } from 'react-router-dom';

type Props = {
  reset?: MouseEventHandler;
};

function ErrorFallback({ reset }: Props): ReactElement {
  const errorTitle = 'Si è verificato un errore';
  const errorMessage = reset
    ? 'Ci scusiamo per il disagio! ' +
      'Siamo continuamente al lavoro per correggere errori come questo.'
    : 'La pagina richiesta non è stata trovata';
  return (
    <Center>
      <CloudShowers />
      <Title>Oops! {errorTitle}</Title>
      <p>{errorMessage}</p>
      <A to="/" onClick={reset}>
        Torna alla home
      </A>
    </Center>
  );
}

const Center = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: var(--space-4);
  & svg {
    width: 5rem;
    height: 5rem;
    fill: var(--primary);
    margin-bottom: var(--space-3);
  }
`;

const Title = styled.h2`
  margin-top: var(--space-4);
  margin-bottom: var(--space-3);
`;

const A = styled(Link)`
  display: flex;
  align-items: center;
  border-radius: 8px;
  min-height: 44px;
  margin-top: var(--space-3);
  padding: 0 var(--space-2);
  font-weight: 500;
  color: var(--on-background);
  &:hover,
  :focus {
    color: var(--primary);
    background-color: var(--primary-container);
  }
`;

export default ErrorFallback;
