import { UserReadableError } from '../models/errors';

export class FetchError extends Error {
  response: Response;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any;
  constructor(response: Response) {
    super(
      `HTTP request to ${response.url} failed with status code ${response.status}`,
    );
    this.response = response;
    Object.setPrototypeOf(this, FetchError.prototype);
  }
  async init(): Promise<void> {
    try {
      this.data = await this.response.json();
    } catch {
      this.data = {};
    }
  }
}

const isErrorMessage = (error: unknown): error is UserReadableError =>
  !!error &&
  typeof error === 'object' &&
  'message' in error &&
  'userReadableError' in error;

export const isUserReadableErrorMessage = (
  error: unknown,
): error is UserReadableError =>
  isErrorMessage(error) && error.userReadableError;
