import * as React from 'react';
import { Navigate, Routes, Route, Outlet } from 'react-router-dom';
import { useAppContext } from '../../context/app.context';
import AppLayout from './AppLayout';
import Spinner from '../../components/spinner/Spinner';
import NotFound from '../error-fallback';
import GlobalErrorBoundary from '../error-fallback/GlobalErrorBoundary';
import { useAutoRefreshSession } from '../../api/session';
import Informazioni from '../informazioni';
import { useSentryContext } from '../../integration/sentry';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import { NotificationsContextProvider } from '../../context/notifications.context';
import { UnreadNewsDialog } from '../news/UnreadNewsDialog';

const StudentList = React.lazy(
  () => import(/* webpackChunkName: "student-list" */ '../student-list'),
);
const Home = React.lazy(
  () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "home" */ '../home/Home'
    ),
);
const Voti = React.lazy(() => import(/* webpackChunkName: "voti" */ '../voti'));
const Notes = React.lazy(
  () => import(/* webpackChunkName: "notes" */ '../notes'),
);
const Assenze = React.lazy(
  () => import(/* webpackChunkName: "assenze" */ '../assenze'),
);
const ModificaAssenza = React.lazy(
  () =>
    import(/* webpackChunkName: "modifica-assenza" */ '../modifica-assenza'),
);
const ArgomentiLezione = React.lazy(
  () =>
    import(/* webpackChunkName: "argomenti-lezione" */ '../argomenti-lezione'),
);
const Compiti = React.lazy(
  () => import(/* webpackChunkName: "compiti" */ '../compiti'),
);
const DocumentiScrutinio = React.lazy(
  () =>
    import(
      /* webpackChunkName: "documenti-scrutinio" */ '../documenti-scrutinio'
    ),
);
const Calendario = React.lazy(
  () => import(/* webpackChunkName: "calendario" */ '../calendario'),
);
const DocumentiEventi = React.lazy(
  () =>
    import(/* webpackChunkName: "documenti-eventi" */ '../documenti-eventi'),
);
const EditPassword = React.lazy(
  () => import(/* webpackChunkName: "edit-password" */ '../edit-password'),
);
const Bacheche = React.lazy(
  () => import(/* webpackChunkName: "bacheche" */ '../bacheche'),
);
const Questionari = React.lazy(
  () => import(/* webpackChunkName: "questionari" */ '../questionari'),
);
const Anagrafica = React.lazy(
  () => import(/* webpackChunkName: "anagrafica" */ '../anagrafica'),
);
const Profile = React.lazy(
  () => import(/* webpackChunkName: "profilo" */ '../profile'),
);
const MaterialiPerDocente = React.lazy(
  () =>
    import(
      /* webpackChunkName: "materiali-per-docente" */ '../materiali-per-docente'
    ),
);
const ModificaMaterialiPerDocente = React.lazy(
  () =>
    import(
      /* webpackChunkName: "modifica-materiali-per-docente" */ '../modifica-materiali-per-docente'
    ),
);
const Colloqui = React.lazy(
  () => import(/* webpackChunkName: "colloqui" */ '../colloqui'),
);
const AnniScolastici = React.lazy(
  () => import(/* webpackChunkName: "anni-scolastici" */ '../anni-scolastici'),
);
const Modulistica = React.lazy(
  () => import(/* webpackChunkName: "modulistica" */ '../modulistica'),
);
const AnagraficaAlunno = React.lazy(
  () =>
    import(/* webpackChunkName: "anagrafica-alunno" */ '../anagrafica-alunno'),
);
const Payments = React.lazy(
  () => import(/* webpackChunkName: "pagamenti" */ '../pagamenti/Pagamenti'),
);
const Fascicolo = React.lazy(
  () => import(/* webpackChunkName: "fascicolo" */ '../fascicolo/Fascicolo'),
);
const Elaborato = React.lazy(
  () =>
    import(
      /* webpackChunkName: "elaborato-esame" */ '../elaborato-esame/ElaboratoEsame'
    ),
);
const RichiestaCredenzialiInvalsi = React.lazy(
  () =>
    import(
      /* webpackChunkName: "richiesta-credenziali-invalsi" */ '../invalsi/RichiestaCredenzialiInvalsi'
    ),
);
const Docsity = React.lazy(
  () => import(/* webpackChunkName: "docsity" */ '../docsity'),
);
const News = React.lazy(() => import(/* webpackChunkName: "news" */ '../news'));
const Delegati = React.lazy(
  () => import(/* webpackChunkName: "delegati" */ '../delegati/DelegatiRoutes'),
);
const Pcto = React.lazy(
  () => import(/* webpackChunkName: "pcto" */ '../pcto/PctoRoutes'),
);
export const routes = [
  { path: '/voti', component: <Voti /> },
  { path: '/voti/:idMateria', component: <Voti /> },
  { path: '/note', component: <Notes /> },
  { path: '/note/:idNota', component: <Notes /> },
  { path: '/assenze', component: <Assenze /> },
  { path: '/assenze/:idAssenza', component: <Assenze /> },
  { path: '/assenze/nuova', component: <ModificaAssenza /> },
  { path: '/assenze/:idAssenza/modifica', component: <ModificaAssenza /> },
  { path: '/argomenti-lezione', component: <ArgomentiLezione /> },
  { path: '/argomenti-lezione/:idArgomento', component: <ArgomentiLezione /> },
  { path: '/compiti', component: <Compiti /> },
  { path: '/calendario', component: <Calendario /> },
  { path: '/calendario/:idEvento', component: <Calendario /> },
  { path: '/documenti-eventi', component: <DocumentiEventi /> },
  {
    path: '/documenti-eventi/:tipo',
    component: <DocumentiEventi />,
  },
  {
    path: '/documenti-eventi/:tipo/:idEvento',
    component: <DocumentiEventi />,
  },
  { path: '/colloqui/*', component: <Colloqui /> },
  {
    path: '/materiali-per-docente',
    component: <MaterialiPerDocente />,
  },
  {
    path: '/materiali-per-docente/:idMateriale',
    component: <MaterialiPerDocente />,
  },
  {
    path: '/materiali-per-docente/nuovo',
    component: <ModificaMaterialiPerDocente />,
  },
  {
    path: '/materiali-per-docente/:idMateriale/modifica',
    component: <ModificaMaterialiPerDocente />,
  },
  { path: '/elaborato', component: <Elaborato /> },
  { path: '/documenti-scrutinio', component: <DocumentiScrutinio /> },
  { path: '/bacheche', component: <Bacheche /> },
  { path: '/questionari/*', component: <Questionari /> },
  { path: '/anagrafica', component: <Anagrafica /> },
  { path: '/anagrafica-alunno', component: <AnagraficaAlunno /> },
  { path: '/profile', component: <Profile /> },
  { path: '/anni-scolastici', component: <AnniScolastici /> },
  { path: '/modulistica/*', component: <Modulistica /> },
  { path: '/pagamenti/*', component: <Payments /> },
  { path: '/documenti-fascicolo', component: <Fascicolo /> },
  { path: '/documenti-fascicolo/:idDocumento', component: <Fascicolo /> },
  {
    path: '/richiesta-credenziali-invalsi',
    component: <RichiestaCredenzialiInvalsi />,
  },
  { path: '/studyaway', component: <Docsity /> },
  { path: '/news', component: <News /> },
  { path: '/pcto/*', component: <Pcto /> },
  { path: '/delegati-al-ritiro/*', component: <Delegati /> },
];

function Authenticated() {
  useDocumentTitle();
  return (
    <RequireAuth>
      <NotificationsContextProvider>
        <AuthApp />
      </NotificationsContextProvider>
    </RequireAuth>
  );
}

function AuthApp() {
  const [state] = useAppContext();
  useAutoRefreshSession();
  const { user, student } = state;
  useSentryContext({
    username: user?.username,
    codiceCliente: user?.codiceCliente,
    studentId: student?.id,
  });
  return (
    <AppLayout>
      <React.Suspense fallback={<Spinner withMargin />}>
        <Routes>
          <Route path="/alunni" element={<StudentList />} />
          <Route path="/modifica-password" element={<EditPassword />} />
          <Route path="/info" element={<Informazioni />} />
          <Route
            path="/"
            element={
              <RequireStudent>
                <UnreadNewsDialog />
                <Outlet />
              </RequireStudent>
            }
          >
            <Route
              index
              element={
                <GlobalErrorBoundary>
                  <Home />
                </GlobalErrorBoundary>
              }
            />
            {routes.map(({ path, component }) => (
              <Route
                key={path}
                path={path}
                element={<GlobalErrorBoundary>{component}</GlobalErrorBoundary>}
              />
            ))}
            <Route path="*" element={<NotFound />} />
          </Route>
        </Routes>
      </React.Suspense>
    </AppLayout>
  );
}

type ChildrenProp = {
  children?: React.ReactNode;
};

const RequireAuth = ({ children }: ChildrenProp) => {
  const [{ user }] = useAppContext();
  const isAuthenticated = !!user && !!user.token;
  return isAuthenticated ? <>{children}</> : <Navigate to="/signin" />;
};

const RequireStudent = ({ children }: ChildrenProp) => {
  const [{ student, settings }] = useAppContext();
  const isStudentSelected = !!student && !!settings;
  return isStudentSelected ? <>{children}</> : <Navigate to="/alunni" />;
};

export default Authenticated;
