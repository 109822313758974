import * as React from 'react';
import { getBaseRoute } from '../integration';
import { head } from '../shared-logic/fpUtils';
import { capitalizeFirstLetter } from '../shared-logic/text';
import { useLocation } from 'react-router-dom';

function useDocumentTitle() {
  const { pathname } = useLocation();

  React.useEffect(() => {
    const page = getPage(pathname);
    const area = getArea();

    document.title = buildTitle([page, area, 'Nuvola']);
  }, [pathname]);
}

function getPage(pathname: string) {
  const base = getBaseRoute();
  const mainPath = head(
    pathname.split('/').filter(path => path && path !== base),
  );
  return capitalizeFirstLetter(mainPath)?.replace(/-/g, ' ');
}

function getArea() {
  const { pathname } = window.location;
  return pathname.includes('area-tutore')
    ? 'Area tutore'
    : pathname.includes('area-studente')
      ? 'Area studente'
      : undefined;
}

function buildTitle(words: Array<string | undefined>) {
  return words.filter(Boolean).join(' - ');
}

export default useDocumentTitle;
