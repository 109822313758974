import React from 'react';
import { styled } from 'styled-components';
import Error from '../../../../assets/icons/exclamation-circle.svg';

type Props = {
  errors?: string[];
  dangerouslySetHTML?: boolean;
};

function InputErrors({ errors, dangerouslySetHTML }: Props) {
  if (!errors?.length) {
    return null;
  }
  return (
    <div>
      {errors.map((error, index) => (
        <ErrorMessage key={index}>
          <Error />
          {dangerouslySetHTML ? (
            <small dangerouslySetInnerHTML={{ __html: error }} />
          ) : (
            error
          )}
        </ErrorMessage>
      ))}
    </div>
  );
}

const ErrorMessage = styled.small`
  color: var(--error);
  margin: var(--space-2);
  display: flex;
  align-items: center;
  & svg {
    width: 0.8rem;
    height: 0.8rem;
    fill: var(--error);
    margin: 0 var(--space-1);
  }
`;

export default InputErrors;
