import React, {
  createContext,
  useContext,
  ReactElement,
  ReactNode,
  Dispatch,
  SetStateAction,
} from 'react';
import Snackbar, { useSnackbarMessage } from '../components/snackbar';

type SnackbarContextType = [string, Dispatch<SetStateAction<string>>];

const initialContext: SnackbarContextType = ['', () => ({})];
const SnackbarContext = createContext(initialContext);

export function useSnackbarContext(): SnackbarContextType {
  return useContext(SnackbarContext);
}

type Props = {
  children: ReactNode;
};

export function SnackbarContextProvider({ children }: Props): ReactElement {
  const [message, setMessage] = useSnackbarMessage(5000);
  return (
    <SnackbarContext.Provider value={[message, setMessage]}>
      {children}
      <Snackbar open={!!message}>{message}</Snackbar>
    </SnackbarContext.Provider>
  );
}
